<template>
  <v-data-table
    class="elevation-1"
    :items="data"
    :headers="computedHeaders"
    :loading="tableLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small class="mr-2" @click="edit(item)">
            mdi-pencil
          </v-icon>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small @click="remove(item)"> mdi-delete </v-icon>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>
    <template v-slot:item.tipo="{ item }">
      {{ getTipo(item.tipo) }}
    </template>
    <template v-slot:item.garantia="{ item }">
      {{ item.garantia }}
      {{
        item.garantia == null
          ? "Sem Informação"
          : item.garantia == 1
          ? "mês"
          : "meses"
      }}
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Inventário</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-btn
          v-if="$state.role == 'eqpdtiuo'"
          color="primary"
          dark class="mb-2"
          @click="deleteData()"
          :loading="task_in_progress"
        >
          Apagar Inventário
        </v-btn>

        <v-spacer></v-spacer>

        <v-dialog v-model="dialog2" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="$state.role == 'eqpdtiuo'" color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Upload CSV</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Upload Inventário CSV</span>
            </v-card-title>
            <ValidationObserver ref="form" v-slot="{ passes }">
              <v-form @submit.prevent="passes(save)" width="100%">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-alert
                          type="info"
                          :value="true"
                          elevation="2"
                          icon="mdi-information"
                        >
                          O arquivo deve estar no formato CSV (separado por <strong>VÍRGULA</strong>) e o cabeçalho deve conter as seguintes colunas:
                          <br />
                          <br />
                          <strong>
                            TIPO, NOME, DESCRICAO, ANO_AQUISICAO, GARANTIA, FINALIDADE, SETOR, QUANTIDADE
                          </strong>
                          <br />
                          <br />
                          É possível baixar o modelo do arquivo CSV clicando <a href="https://drive.google.com/file/d/1tpKcw-iS5TKycIz1333fJVCS_PefE1Wk/view?usp=sharing" target="_blank" download>aqui</a>.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row>
                        <input type="file" accept=".csv" ref="fileInput" label="Arquivo de Inventário no formato CSV" @change="arquivoCsv = $event.target.files[0]" :error-messages="uploadCsvErrors" />
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn :loading="saving" color="darken-1" text @click="uploadCsv"
                    >Enviar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Inventário</span>
            </v-card-title>
            <ValidationObserver ref="form" v-slot="{ passes }">
              <v-form @submit.prevent="passes(save)" width="100%">
                <v-card-text>
                  <v-container>
                    <ValidationProvider
                      v-if="$state.role != 'eqpdtiuo'"
                      vid="uo"
                      name="Unidade Organizacional"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <UOAutoComplete
                        v-model="Item.uo"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      vid="tipo"
                      name="Tipo"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-select
                        :items="tipos"
                        v-model="Item.tipo"
                        item-text="text"
                        item-value="value"
                        :error-messages="errors"
                        label="Tipo"
                      ></v-select>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="nome"
                      name="Nome"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.nome"
                        label="Nome"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="descricao"
                      name="Descrição"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-textarea
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors"
                      ></v-textarea>
                    </ValidationProvider>

                    <ValidationProvider
                      vid="ano_aquisicao"
                      name="Ano de Aquisição"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.ano_aquisicao"
                        label="Ano de aquisição"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <v-switch
                      v-model="has_garantia"
                      label="Tem garantia?"
                    ></v-switch>
                    <ValidationProvider
                      vid="garantia"
                      name="Garantia"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="Item.garantia"
                        label="Garantia (meses)"
                        :error-messages="errors"
                        v-if="has_garantia"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="finalidade"
                      name="Finalidade"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.finalidade"
                        label="Finalidade"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="setor"
                      name="Setor"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.setor"
                        label="Setor"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="quantidade"
                      name="Quantidade"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        type="number"
                        v-model="Item.quantidade"
                        label="Quantidade"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn :loading="saving" color="darken-1" text @click="save"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Inventario, InventarioUploadCsv } from "@/api/eqpdti";
import UOAutoComplete from "@/components/UOAutoComplete";
export default {
  name: "Inventario",
  components: {
    UOAutoComplete,
  },
  data: () => ({
    data: [],
    tipos: [
      {
        value: 1,
        text: "Software",
      },
      {
        value: 2,
        text: "Impressora",
      },
      {
        value: 3,
        text: "Servidor",
      },
      {
        value: 4,
        text: "Switch",
      },
      {
        value: 5,
        text: "Desktop",
      },
      {
        value: 6,
        text: "Monitor",
      },
      {
        value: 8,
        text: "Firewall",
      },
      {
        value: 9,
        text: "Ap Wireless",
      },
      {
        value: 10,
        text: "Workstation",
      },
      {
        value: 11,
        text: "Notebook",
      },
      {
        value: 12,
        text: "Computador",
      },
      {
        value: 7,
        text: "Outros",
      },
    ],
    headers: [
      {
        text: "Tipo",
        value: "tipo",
        align: "center",
      },
      {
        text: "Nome",
        value: "nome",
        align: "left",
      },
       {
        text: "Setor",
        value: "setor",
        align: "center",
      },

      {
        text: "Ano de aquisição",
        value: "ano_aquisicao",
        align: "center",
      },
      {
        text: "Garantia",
        value: "garantia",
        align: "center",
      },
      {
        text: "Quantidade",
        value: "quantidade",
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    Item: {
      inicio: "",
      fim: "",
    },
    dialog: false,
    dialog2: false,
    saving: false,
    tableLoading: false,
    errors: [],
    has_garantia: true,
    arquivoCsv: null,
    uploadCsvErrors: [],
    task_in_progress: false,
  }),
  computed: {
    computedHeaders() {
      this.fetch();
      if (this.$state.role != "eqpdtiuo") {
        return [
          {
            text: "Unidade Organizacional",
            value: "uo_sigla",
            align: "center",
          },
        ].concat(this.headers);
      }
      return this.headers;
    },
  },
  methods: {
    getTipo(tipo) {
      let f = this.tipos.filter((i) => i.value == tipo);
      if (f.length > 0) {
        return f[0].text;
      }
      return "-";
    },
    close() {
      this.dialog = false;
      this.dialog2 = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          if (!this.has_garantia) {
            this.Item.garantia = null;
          }
          this.saving = true;
          const _then = () => {
            this.saving = false;
            this.close();
            this.fetch();
          };
          const _catch = (error) => {
            this.saving = false;
            this.errors = error.response.data;
            if (this.errors["non_field_errors"]) {
              this.$emitter.emit(
                "alert:error",
                this.errors["non_field_errors"][0]
              );
            } else {
              this.$emitter.emit("alert:error", this.errors);
            }
          };

          if (this.Item["id"]) {
            let id = this.Item["id"];

            Inventario.update(id, this.Item).then(_then).catch(_catch);
          } else {
            Inventario.post(this.Item).then(_then).catch(_catch);
          }
        }
      });
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.$nextTick(() => {
        this.has_garantia = item.garantia != null;
      });

      this.dialog = true;
    },

    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
      this.tableLoading = true;
      Inventario.remove(item["id"]).then(() => {
        this.tableLoading = false;
        this.fetch();
      });
      }
    },
    fetch() {
      this.tableLoading = true;
      Inventario.list().then((response) => {
        this.data = response.data;
        this.tableLoading = false;
      });
    },
    uploadCsv() {
      this.saving = true;
      this.uploadCsvErrors = [];
      const formData = new FormData();
      formData.append("arquivo", this.arquivoCsv);
      InventarioUploadCsv.post(formData)
        .then(() => {
          this.saving = false;
          this.$refs.fileInput.value = "";
          this.close();
          this.fetch();
          this.$emitter.emit("alert:success", "Inventário atualizado com sucesso.");
        })
        .catch((error) => {
          this.saving = false;
          this.$refs.fileInput.value = "";
          this.uploadCsvErrors = error.response.data;

          if (this.uploadCsvErrors["non_field_errors"]) {
            this.$emitter.emit(
              "alert:error",
              this.uploadCsvErrors["non_field_errors"][0]
            );
          } else {
            this.$emitter.emit("alert:error", this.uploadCsvErrors);
          }
        });
    },
    deleteData() {
      if (window.confirm("Deseja realmente apagar o inventário atual?")) {
        this.tableLoading = true;
        this.task_in_progress = true;
        Inventario.deleteData().then(() => {
          this.tableLoading = false;
          this.task_in_progress = false;
          this.fetch();
          this.$emitter.emit("alert:success", "Inventário apagado com sucesso.");
        }).catch((error) => {
          this.tableLoading = false;
          this.task_in_progress = false;
          this.errors = error.response.data;

          if (this.errors["non_field_errors"]) {
            this.$emitter.emit(
              "alert:error",
              this.errors["non_field_errors"][0]
            );
          } else {
            this.$emitter.emit("alert:error", this.errors);
          }
        })
        ;
      }
    },
  },
  // mounted() {
  //   this.$emitter.emit('alert:info', 'O inventário foi pré-carregado com os dados do PDTIC anterior.')
  // },
};
</script>
